import React, { useEffect } from 'react';

import { navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';
import { StringParam, useQueryParam } from 'use-query-params';

import { Layout } from '../components';
import UserList from '../components/UserList';

const strings = new LocalizedStrings({
    en: {
        visitors: 'Visitors',
        listOfVisitors: 'A list of event visitors',
    },
    de: {
        visitors: 'Besucher',
        listOfVisitors: 'Besucherliste',
    },
});

const Visitor = () => {
    const [clientId] = useQueryParam('clientId', StringParam);
    const [eventId] = useQueryParam('eventId', StringParam);

    useEffect(() => {
        if (!clientId || !eventId) navigate('/');
    }, []);

    return (
        <Layout title={strings.visitors} subTitle={strings.listOfVisitors}>
            <UserList clientId={clientId} filterRoles={['visitor']} />
        </Layout>
    );
};

export default Visitor;
